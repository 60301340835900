
import { Component, Prop, Vue } from 'vue-property-decorator';
import { QSkeleton } from 'quasar';

import { cloneDeep, get } from 'lodash-es';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsButton from '@WS_Components/WsButton.vue';
import { safePush } from '@/router';
import {
  getManagementInfo,
  getMonitoringInfo,
  getMonitoringOptions,
  getServerInfo,
  getServerOptions,
  orderManagement,
  orderMonitoring,
  orderServer,
} from '@/services/scf-api.service';
import SidebarLayout from '@/components/SidebarLayout.vue';
import SidebarLayoutItem from '@/components/SidebarLayoutItem.vue';
import PropertiesSummary from '@/components/PropertiesSummary.vue';
import ProductInfo from '@/components/contactForm/ProductInfo.vue';
import ContactFormAdditional from '@/components/contactForm/ContactFormAdditional.vue';
import ContactFormUserData from '@/components/contactForm/ContactFormUserData.vue';
import { CF_USECASE } from '@/components/contactForm/const.enum';
import {Throttle} from '@loopia-group/utils';

const defaultModel = {
  name: '',
  contact: {
    login: '',
    name: '',
    email: '',
  },
};

const defaultServerModel = {
  ...defaultModel,
  ipAddress: { type: 'one', price: { actualPrice: 0 } },
  monitoring: { type: 'basic', price: { actualPrice: 0 } },
  os: null,
  licences: [],
  backup: { volume: 0 },
  period: 1,
};

@Component({
  components: {
    QSkeleton,
    WsMessage,
    WsButton,
    SidebarLayout,
    SidebarLayoutItem,
    PropertiesSummary,
    ProductInfo,
    ContactFormAdditional,
    ContactFormUserData,
  },
})
export default class ContactForm extends Vue {
  @Prop()
  readonly usecase!: CF_USECASE;

  loadingInfo = true;
  loadingOptions = true;

  productInfo: any = null;
  productOptions: any = null;

  scfInfoPath: string = 'scf.info';
  cfOptionsPath: string = 'scf.options';

  model: any = { ...defaultModel };
  CF_USECASE = CF_USECASE;

  step = 1;
  checkoutInProgress = false;

  get summaryProperties(): Record<string, string[]> {
    const monitoring: string[] = [];
    const productOptions = this.productOptions;
    if (!productOptions) {
      return {};
    }

    if (this.usecase === CF_USECASE.MONITORING) {
      productOptions.forEach((section: any) => {
        if (!section.options) {
          return;
        }
        section.options.forEach((option: any) => {
          if (this.model[option.name] === true) {
            monitoring.push(section.name + '.' + option.name);
          }
        });
      });
    }

    return {
      server: ['monitoring', 'ipAddress', 'os', ...this.optionalBackup],
      monitoring,
      management: [
        'systemMonitoring',
        'supervision',
        'installation',
        'tunning',
        'updates',
      ],
    };
  }

  get optionalBackup() {
    return this.model?.backup?.volume ? ['backup'] : [];
  }

  get mainMessage() {
    return {
      title: `scf.message.${this.usecase}.title`,
      info: `scf.message.${this.usecase}.info`,
    };
  }

  async created() {
    if (this.usecase === CF_USECASE.MANAGEMENT) {
      this.step = 2;
    }

    this.resetModel();
    this.getProductInfo();
    this.getOptions();
  }

  resetModel() {
    let model: Record<string, any> = { ...defaultModel };
    if ([CF_USECASE.MANAGEMENT, CF_USECASE.MONITORING].includes(this.usecase)) {
      model.period = 1;
    }
    if (this.usecase === CF_USECASE.SERVER) {
      model = { ...defaultServerModel };
    } else {
      this.summaryProperties[this.usecase]?.forEach((prop: string) => {
        model[prop] = false;
      });
    }
    this.model = model;
  }

  async getProductInfo() {
    this.loadingInfo = true;
    const { uuid, period } = get(this.$route, 'query', {});
    if (period) {
      this.model.period = parseInt(period);
    }

    try {
      if (this.usecase === CF_USECASE.SERVER) {
        this.model.dedicatedServerUuid = uuid;

        if (this.model.dedicatedServerUuid) {
          const response = await getServerInfo(this.model.dedicatedServerUuid);
          this.productInfo = {
            ...response.data,
            usecase: CF_USECASE.SERVER,
          };
        } else {
          this.$nextTick(() => {
            // wait for ws-message component to be mounted
            this.$messageService.errorHandler(this.scfInfoPath)(
              'scf.error.uuid_required'
            );
          });
        }
      } else if (this.usecase === CF_USECASE.MONITORING) {
        const response: any = await getMonitoringInfo();
        this.productInfo = {
          usecase: CF_USECASE.MONITORING,
          prices: response.data?.prices,
          title: 'scf.monitoring_info.title',
        };
      } else if (this.usecase === CF_USECASE.MANAGEMENT) {
        const response: any = await getManagementInfo();
        this.productInfo = {
          usecase: CF_USECASE.MANAGEMENT,
          prices: response.data?.prices,
          title: 'scf.management_info.title',
        };
      }
    } catch (err) {
      this.$messageService.errorHandler(this.scfInfoPath)(err);
    }
    this.loadingInfo = false;
  }

  async getOptions() {
    try {
      if (this.usecase === CF_USECASE.MONITORING) {
        const response = await getMonitoringOptions();

        // TODO require BE to send it already in this format: {name: string, options: string[]}[]
        const productOptions = Object.entries(
          response.data
        ).map(([key, value]) => ({ name: key, options: value }));
        // initiate model
        productOptions.forEach((section: any) => {
          section.options.forEach((option: any) => {
            Vue.set(this.model, option.name, false);
          });
        });
        this.productOptions = productOptions;
      } else if (this.usecase === CF_USECASE.SERVER) {
        const response = await getServerOptions();
        const productOptions = response.data;
        if (productOptions.backup) {
          this.model.backup = {
            ...this.model.backup,
            ...productOptions.backup,
          };
        }
        this.productOptions = productOptions;
      } else {
        this.productOptions = {};
      }
    } catch (err) {
      this.$messageService.errorHandler(this.cfOptionsPath)(err);
    }

    this.loadingOptions = false;
  }

  @Throttle(1000, {trailing: false})
  async submit() {
    this.$messageService.clearMessages('general');

    const userDataForm = this.$refs.userData as ContactFormUserData;
    const validData = await userDataForm.validate();

    if (!validData) {
      return;
    }

    const requestData = this.getRequestData(
      userDataForm.userExistence === 'old'
    );
    this.checkoutInProgress = true;

    try {
      await ({
        server: orderServer,
        monitoring: orderMonitoring,
        management: orderManagement,
        // eslint-disable-next-line no-unused-vars
      } as Record<CF_USECASE, (requestData: any) => void>)[this.usecase](
        requestData
      );
      // clear model
      this.resetModel();
      safePush({ name: 'thank-you', query: { messageKey: 'mail' } });
    } catch (err) {
      this.$messageService.errorHandler('scf')(err);
    }

    this.checkoutInProgress = false;
  }

  getRequestData(oldUser: boolean) {
    let requestData = cloneDeep(this.model);

    if (oldUser) {
      delete requestData.contact.name;
      delete requestData.contact.organisation;
      delete requestData.contact.email;
      delete requestData.contact.phone;
    } else {
      delete requestData.contact.login;
    }

    if (this.usecase === CF_USECASE.SERVER) {
      const licences = requestData.licences.length
        ? requestData.licences.map((l: any) => l.type)
        : undefined;
      requestData = {
        ...requestData,
        // cleaning and transforming for BE model requirements
        monitoring: requestData.monitoring.type,
        ipAddress: requestData.ipAddress.type,
        backup: requestData.backup.volume || undefined,
        os: requestData.os.type || undefined,
        licences,
      };
    }

    if (this.usecase === CF_USECASE.MONITORING) {
      // workaround until fixed on BE
      // ref: https://loopiagroup.atlassian.net/browse/PNO-2749
      requestData.email = requestData.contact.email;
    }

    return requestData;
  }
}

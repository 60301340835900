
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { QItem, QItemLabel, QItemSection } from 'quasar';

import { WsSelect } from '@WS_Components/WsSelect.vue';
import { WsIcon } from '@WS_Components/WsIcon.vue';
import SidebarLayoutItem from '@/components/SidebarLayoutItem.vue';
import PricePeriod from '@/components/PricePeriod.vue';
import { CF_USECASE } from '@/components/contactForm/const.enum';

interface Price {
  actualPrice: number;
  marketingPrice: number;
  period: number;
}

@Component({
  components: {
    QItem,
    QItemLabel,
    QItemSection,
    WsIcon,
    WsSelect,
    PricePeriod,
    SidebarLayoutItem,
  },
})
export default class ProductInfo extends Vue {
  @Prop({ default: () => ({ prices: {} }) })
  readonly productInfo!: any;
  @Prop({ type: Number, default: 1 })
  readonly period!: number;
  selectedPeriod = 1;

  data() {
    return {
      CF_USECASE,
    };
  }

  get periodOptions(): number[] {
    return this.productInfo.prices.map((price: Price) => price.period);
  }

  @Watch('period', { immediate: true })
  onPeriodChanged(period: number) {
    this.selectedPeriod = period;
  }

  @Watch('selectedPeriod')
  onSelectedPeriodChanged(period: number) {
    if (period !== this.period) {
      this.$emit('period-change', period);
    }
  }
}


import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { QForm } from 'quasar';

import {
  validateForm,
  required,
  addValidations,
  productPropertyList,
  propertyRestriction,
  propertyRules,
} from '@WS_UIkit';
import { Validator } from '@WS_UIkit/types';
import WsInput from '@WS_Components/WsInput.vue';
import WsBtnToggle from '@WS_Components/WsBtnToggle.vue';
import SidebarLayoutItem from '@/components/SidebarLayoutItem.vue';
import ConsentCheckboxes from '@/components/ConsentCheckboxes.vue';
import PricePeriod from '@/components/PricePeriod.vue';
import { CF_USECASE } from './const.enum';
import ContactFormUserNew from './ContactFormUserNew.vue';
import ContactFormUserOld from './ContactFormUserOld.vue';

@Component({
  components: {
    QForm,
    WsInput,
    WsBtnToggle,
    SidebarLayoutItem,
    ConsentCheckboxes,
    PricePeriod,
    ContactFormUserNew,
    ContactFormUserOld,
  },
})
export default class ContactFormUserData extends Vue {
  @Prop()
  readonly usecase!: string;
  @Prop({ default: () => ({ contact: {} }) })
  readonly value!: any;
  @Prop({ default: () => ({}) })
  readonly productOptions!: any;

  loading = false;
  public userExistence = 'old';
  CF_USECASE = CF_USECASE;
  required = required;
  serverNameRules!: Validator[];
  serverNameRestriction!: string;

  get model() {
    return { ...this.value };
  }
  set model(value: any) {
    this.$emit('input', value);
  }

  created() {
    addValidations([productPropertyList]);
    this.serverNameRestriction = propertyRestriction('server_name');
    this.serverNameRules = propertyRules('server_name');
  }

  @Watch('usecase')
  onNoteChange(usecase: string) {
    if (usecase === this.CF_USECASE.MANAGEMENT) {
      Vue.set(this.model, 'note', '');
    }
  }

  public async validate() {
    const consentCheckboxesCmp = this.$refs.consents as ConsentCheckboxes;
    const result = await validateForm(this.$refs.form as Element & QForm);
    if (!result || !consentCheckboxesCmp?.validateCheckboxes?.()) {
      return false;
    }

    this.model.termsAndConditionsConsent =
      consentCheckboxesCmp.termsAndConditionsConsent;
    this.model.thirdPartyConsent = consentCheckboxesCmp.thirdPartyConsent;

    this.reEmit();
    return true;
  }

  reEmit() {
    this.$emit('input', this.model);
  }
}

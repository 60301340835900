
import { computed, defineComponent, ref, watch } from 'vue';
import {WsCheckbox} from './WsCheckbox.vue';

export default defineComponent( {
  name: 'WsCheckboxList',
  components: {
    WsCheckbox,
  },
  props: {
    value: { type: undefined, default: undefined },
    items: {
      type: Array,
      default: () => [],
    },
    uidProp: {
      type: String,
      default: 'id',
    },
    showSelectedOnly: {
      type: Boolean
    },
    inEdit: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Number,
      default: 1, // expected one of 1,2,3,4,6,12
    }
  },
  setup(props) {
    const valueData = ref(props.value);

    watch(
      () => props.value,
      (newValue) => {
        valueData.value = newValue;
      }
    );

    const itemsFiltered = computed(() => {
      return props.showSelectedOnly
        ? props.items.filter((item: Array<string>): boolean => {
          return valueData?.[item[props.uidProp]] === true;
        })
        : props.items;
    });

    const itemsFilteredSplitedColumns = computed( () => {
      if (!props.columns) {
        return [itemsFiltered];
      }

      const items = itemsFiltered.value;
      const limit = Math.ceil(items.length / props.columns);
      const resultItems: any[][] = [];
      let chunk: any[] = [];

      items.forEach((item: any, index: number) => {
        if (index && index % limit === 0) {
          resultItems.push(chunk);
          chunk = [];
        }
        chunk.push(item);
      });
      resultItems.push(chunk);

      return resultItems;
    });

    return { props, valueData, itemsFilteredSplitedColumns };
  }

});


import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { QIcon, QSpinner } from 'quasar';
import { mdiCheck } from '@quasar/extras/mdi-v4';

import {
  validateInput,
  required,
  propertyValidation,
  generalPropertyList,
  addValidations,
} from '@WS_UIkit';
import { PropertyValidation } from '@WS_UIkit/types';
import WsInput from '@WS_Components/WsInput.vue';
import { checkLogin } from '@/services/user-api.service';
import { LoginCheckResponse } from '@/types';

@Component({
  components: {
    QIcon,
    QSpinner,
    WsInput,
  },
})
export default class ContactFormUserOld extends Vue {
  @Prop({ default: () => ({ contact: {} }) })
  readonly value!: any;

  loading = false;
  login = '';
  path = 'scf.contact.login';
  loginValidation!: PropertyValidation;

  data() {
    return {
      mdiCheck,
      required,
    };
  }

  get model() {
    return { ...this.value };
  }

  @Watch('loading')
  loadingWatch(loading: boolean) {
    this.$emit('loading', loading);
  }

  @Watch('model.contact.login', { immediate: true })
  loginWatch(login: string) {
    this.login = login || '';
  }

  created() {
    addValidations([generalPropertyList]);
    this.loginValidation = propertyValidation('login');
  }

  async checkLoginExistence(login: string) {
    await this.$nextTick(); // wait for qField to update internal values so validation is evaluated correctly
    const result = await validateInput(this.$refs.login);
    if (!result) {
      return;
    }

    this.loading = true;

    try {
      const data: LoginCheckResponse = await checkLogin(login);
      if (login !== this.login) {
        return; // ignore if it is not latest value
      }

      if (data.free) {
        this.$messageService.errorHandler(this.path)(
          'validation.login.missing'
        );
      } else {
        this.model.contact.login = login;
        this.$messageService.clearMessages(this.path);
        this.reEmit();
      }
    } catch (err) {
      if (login !== this.login) {
        return; // ignore if it is not latest value
      }
      this.$messageService.errorHandler(this.path)(err);
    }

    this.loading = false;
  }

  reEmit() {
    this.$emit('input', this.model);
  }
}

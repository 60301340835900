import { render, staticRenderFns } from "./WsCheckboxList.vue?vue&type=template&id=52501a3c&scoped=true"
import script from "./WsCheckboxList.vue?vue&type=script&lang=ts"
export * from "./WsCheckboxList.vue?vue&type=script&lang=ts"
import style0 from "./WsCheckboxList.vue?vue&type=style&index=0&id=52501a3c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52501a3c",
  null
  
)

export default component.exports
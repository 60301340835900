
import { Component, Vue, Prop } from 'vue-property-decorator';
import { QForm } from 'quasar';

import { cloneDeep } from 'lodash-es';
import { getSnakedKeyFactory } from '@loopia-group/utils';
import {
  validateForm,
  required,
  addValidations,
  productPropertyList,
  propertyValidation,
} from '@WS_UIkit';
import { PropertyValidation } from '@WS_UIkit/types';
import WsInput from '@WS_Components/WsInput.vue';
import WsCheckboxList from '@WS_Components/WsCheckboxList.vue';
import WsEdit from '@WS_Components/WsEdit.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsBack from '@WS_Components/WsBack.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import config from '@/services/config.service';
import { uniqueServerName } from '@/services/validation.service';
import { CF_USECASE } from '@/components/contactForm/const.enum';
import SidebarLayoutItem from '@/components/SidebarLayoutItem.vue';
import PricePeriod from '@/components/PricePeriod.vue';
import PropertySlider from '@/components/xSell/PropertySlider.vue';
import PropertyRadio from '@/components/xSell/PropertyRadio.vue';
import PropertyOs from '@/components/xSell//PropertyOs.vue';

@Component({
  components: {
    QForm,
    WsInput,
    WsCheckboxList,
    WsEdit,
    WsButton,
    WsBack,
    WsMessage,
    SidebarLayoutItem,
    PricePeriod,
    PropertyOs,
    PropertySlider,
    PropertyRadio,
  },
})
export default class ContactFormAdditional extends Vue {
  @Prop({ default: () => ({}) })
  readonly value!: any;
  @Prop()
  readonly usecase!: string;
  @Prop({ default: () => ({}) })
  readonly productOptions!: any;
  @Prop(Boolean)
  readonly inEdit!: boolean;

  loading = false;
  backUrl = config.dedicatedServersUrl || '';
  serverNameValidation!: PropertyValidation;

  data() {
    return {
      required,
      uniqueServerName,
      CF_USECASE,
    };
  }

  get model() {
    return cloneDeep(this.value);
  }
  set model(value: any) {
    this.$emit('input', value);
  }

  get hasSelected(): Record<string, boolean> {
    const result: Record<string, boolean> = { any: false };
    for (const key in this.productOptions) {
      const section = this.productOptions[key];
      const sectionResult = !!section.options?.filter(
        (opt: any) => this.model[opt.name]
      ).length;
      result[section.name] = sectionResult;
      if (sectionResult) {
        result.any = true;
      }
    }
    return result;
  }

  created() {
    addValidations(productPropertyList);
    this.serverNameValidation = propertyValidation('server_name');
  }

  submit() {
    validateForm(this.$refs.form as Element & QForm).then((result: boolean) => {
      if (!result) {
        return;
      }
      // else
      this.$emit('continue');
    });
  }

  getKey(prop: string, key: string): string {
    this.getKey = getSnakedKeyFactory('scf.' + this.usecase);
    return this.getKey(prop, key);
  }

  getPropsKey(sectionName: string, itemName: string, key: string): string {
    this.getPropsKey = getSnakedKeyFactory(
      'product.properties.' + this.usecase
    );
    return this.getPropsKey(sectionName, itemName, key);
  }

  reEmit() {
    this.$emit('input', this.model);
  }
}

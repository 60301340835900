
import { Component, Vue, Prop } from 'vue-property-decorator';

import {
  required,
  propertyValidation,
  addressPropertyList,
  addValidations,
} from '@WS_UIkit';
import { Validator } from '@WS_UIkit/types';
import WsInput from '@WS_Components/WsInput.vue';
import WsPhoneInput from '@WS_Components/WsPhoneInput.vue';
import { EditableAddressProps } from '@/WS_UIkit/src/components/WsAddress.enum';

@Component({
  components: {
    WsInput,
    WsPhoneInput,
  },
})
export default class ContactFormUserNew extends Vue {
  @Prop({ default: () => ({}) })
  readonly value!: any;

  required = required;
  propStaticRules: Record<EditableAddressProps, Validator[]> = Object.create(
    null
  );
  propRestriction: Record<EditableAddressProps, string> = Object.create(null);

  created() {
    addValidations([addressPropertyList]);
    (['email', 'name', 'organisation'] as EditableAddressProps[]).forEach(
      prop => {
        const { rules, restriction } = propertyValidation(`address_${prop}`);
        this.propStaticRules[prop] = rules;
        this.propRestriction[prop] = restriction;
      }
    );
  }
}
